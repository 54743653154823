import {ShareGenericApi, UploadCallbackType} from "./ShareGenericApi";
import * as Policy from "./Policies";
import axios from "axios";

export class ShareItemApi extends ShareGenericApi {

    protected item_name: string;
    protected item_id: string 
    protected mount_id: string;
    protected drive_id: string;
    protected path: string;
    protected size: string;
    protected createTime: string;
    protected modifiedTime: string;
    protected mimeType: string;
    protected version : string;

    constructor(_token: string, _base: string, _itemID: string, _mount_id: string/*, _driveID: string, _name: string,
        _path: string, _size: string, _createTime: string, _modifiedTime: string, _mimeType: string, _version: string*/) 
    {
        super(_token, _base);

        this.item_id = _itemID;  
        this.mount_id = _mount_id;


        /*this.drive_id = _driveID;
        this.item_name = _name;
        this.path = _path;
        this.size = _size;
        this.createTime = _createTime;
        this.modifiedTime = _modifiedTime;
        this.mimeType = _mimeType;
        this.version = _version;*/
    }

    protected printLog() 
    {
        console.log("class ShareItemApi");
    }

    protected getShareBody(action: string) {
        let body = {
            recipients: this.shareRequest.contactsTo,
            category: "TS",
            share_name: this.shareRequest.name,
            action: action,
            sharing_policy_id: this.shareRequest.selectedOption.id,
            options: this.getOptions(),
            identity_id: this.identity,
            mount_id: this.mount_id,
            item_id: this.item_id
          };

        if(this.shareRequest.shareID.length != 0)
            body["share_id"] = this.shareRequest.shareID;

        if(this.shareRequest.shareMessageID.length != 0)
            body["share_message_id"] = this.shareRequest.shareMessageID;

          return body;
    }

    public async getShareID(contactsTo: string[], name: string, message: string, policy: Policy.CwpPolicy,
        selectedOptionIndex: number) 
    {
        var ext = name.substr(name.lastIndexOf("."));

        var options = policy["share-options"];
        this.shareRequest.selectedOption = options[selectedOptionIndex];
        this.shareRequest.name = name.replace(ext, "");
        this.shareRequest.message = message;
        this.shareRequest.filename = name;
        this.shareRequest.contactsTo = contactsTo;

        this.callShareEndPoint("create", (status, data, error) => {
            if(status){
                if (data.hasOwnProperty("share")) {
                    let share = data.share;

                    this.shareRequest.shareID = share.id;
                    this.shareRequest.mountID = share.mount_id;
                    //this.shareRequest.shareMessageID = data.share_message_id;

                    this.sendShare();
                }
            }
            else
            {
                if (this.callback != null)
                    this.callback(error, 0, false, true);
            }
        });
    }

    protected async sendShare()
    {
        Office.context.document.settings.set("shareID", this.shareRequest.shareID);
        Office.context.document.settings.set("mountID", this.shareRequest.mountID);
        Office.context.document.settings.set("parentID", this.shareRequest.parentID);
        Office.context.document.settings.set("fileName", this.shareRequest.filename);
        Office.context.document.settings.set("shareMessageID", this.shareRequest.shareMessageID);
        Office.context.document.settings.set("optionName", this.shareRequest.selectedOption.name);

        Office.context.document.settings.set("itemID", this.item_id);
        Office.context.document.settings.set("providerMountID", this.mount_id);

        Office.context.document.settings.saveAsync(function(result) {
            if (result.status === Office.AsyncResultStatus.Succeeded)
                console.log("share info is stored");
            else 
            console.log("share info is NOT stored");
        });

        if(this.callback != null){
            this.createSuccessURL().then(msg => {
                this.callback(msg, 0, false, true);
            })
        }
    }

    
    /*private getSharePointData()
    {
        var sharepoint_data = [{
            drive_id : this.drive_id,
            item_id: this.item_id,
            filename: this.item_name,
            size: this.size,
            file_path: this.path,
            last_modified: this.modifiedTime,
            mimetype: this.mimeType,
            version: this.version
        }];

        return sharepoint_data;
    }

    public async getShareID(contactsTo: string[], name: string, message: string, policy: Policy.CwpPolicy,
        selectedOptionIndex: number) 
    {
        var ext = name.substr(name.lastIndexOf("."));

        var options = policy["share-options"];
        this.shareRequest.selectedOption = options[selectedOptionIndex];
        this.shareRequest.name = name.replace(ext, "");
        this.shareRequest.message = message;
        this.shareRequest.filename = name;
        this.shareRequest.contactsTo = contactsTo;

        let bodyOptions = this.getOptions();
        bodyOptions["show_terms_once"] = false;
        bodyOptions["require_terms_of_use"] = false;

        let body = {
            sharing_policy_id: this.shareRequest.selectedOption.id,
            recipients: this.shareRequest.contactsTo,
            mail_attachments:{
                encrypt: true,
                attachments:[{
                    filename: this.item_name
                }]
            },
            share_name: name,
            send_on_finalize: true,
            message: this.shareRequest.message,
            sharepoint_data: this.getSharePointData(),
            options: bodyOptions,
            identity_id: this.identity
        }

        axios
            .post(this.base + "/api/3.1/shares/bot/sharepoint", body, {
              headers: this.getHeader(),
            })
            .then(response => {
              let data = response.data;
              console.log("response: " + data);
            })
            .catch(error => {
                if (this.callback != null)
                    this.callback(error.meesage, 0, false, true);
            });
        
    }*/
}