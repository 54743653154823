import axios from 'axios';
import {SecureApi} from './SecureApi';
import {LogMessage, LogMessageType} from './Policies';
import * as Policy from './Policies';
import { ShareGenericApi } from './ShareGenericApi';
import { ShareFileApi } from "./ShareFileApi";

let identity = "";
let email = "";
let computername = "office";
let jwt = "";
let policy : Policy.CwpPolicy;

interface LoginCallbackType { (errorCode: number, errorDescription: string): void }

interface PoliciesCallbackType { (policy: Policy.CwpPolicy,  errorCode: number, errorDescription: string): void }

interface ShareCallbackType { (message: string, progress: number, showProgress: boolean, activateButton: boolean): void }


export class OfficeApi {
  private secureApi : SecureApi;
  public shareApi : ShareGenericApi;
  public token: string;
  public baseURL : string;

  constructor(_base){
    this.baseURL = _base;
    this.secureApi = new SecureApi(this.onLogMessage);
    this.secureApi.init(this.baseURL);
    this.token = "";
  }

  public setJwt(_jwt:string)
  {
    jwt = _jwt
  }
  
  public async login(_email: string, password: string, callback: LoginCallbackType) {
    computername = "office_" + _email;
    axios
      .post(this.baseURL + "/api/2.0/device/associate/", { "auth-info": {}, "message": { "email": _email, "password": password, "computername": computername } })
      .then(response => {
        let data = response.data;
        let error = data["error-code"];
        if (error == 0) {
          this.token = data.message.authtoken;

          this.shareApi = new ShareFileApi(this.token, this.baseURL);
          email = _email;
          if (callback != null) callback(0, "");
        } else {
          let details = data["error-details"];
          let code = details.code;
          let description = details.description;

          if (callback != null) callback(code, description);
        }
      })
      .catch(function(error) {
        if (callback != null) callback(-99, error.message);
      });
  }

  public async mslogin(jwt: string, callback: LoginCallbackType) {
    axios
      .post(this.baseURL + "/api/2.0/device/jwt/", { "message": { "jwt": jwt } })
      .then(response => {
        let data = response.data;
        let error = data["error-code"];
      
        if (error == 0) {
          this.token = data.message.authtoken;
          email = data.message.email;
          computername = data.message.computername;
          identity = data.message.identity_guid;

          this.shareApi = new ShareFileApi(this.token, this.baseURL);

          this.shareApi.setIdentity(identity);
          if (callback != null) callback(0, "");
        } else {
          let details = data["error-details"];
          let code = details.code;
          let description = details.description;

          if (callback != null) callback(code, description);
        }
      })
      .catch(function(error) {
        if (callback != null) callback(-99, error.message);
      });
  }

  private getBodyForPolicies()
  {
    if(jwt.length == 0)
      return { "auth-info": {"authtoken": this.token, "email": email, "computername": computername }, message: {} };
    else
      return { "auth-info": {"email": email}, message: {} };
  }

  private getHeaderForPolicies()
  {
    if(jwt.length == 0)
      return {
        'Content-Type': 'application/json',
        'NCC-AUTH-TOKEN': this.token
      };
    else
      return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      };
  }

  public async getPolicies(callback: PoliciesCallbackType) {
  
    let that = this;
    axios
    .post(this.baseURL + "/api/2.0/policy/get/", this.getBodyForPolicies(), {
      headers: this.getHeaderForPolicies()})
    .then(function(response) {
      
      let data = response.data;
      let error = data["error-code"];
      if(error == 0){
        let reply = that.secureApi.parsePolicyResponse(response.data, false);
        var policies = reply.cwpPolicy;

        for(var i=0; i<policies.length ;i++)
        {
          var p = policies[i];
          if(!p.hasOwnProperty("organization-id") || !p.hasOwnProperty("share-options"))
            continue;
          
          policy = p;
          break

        }

        if(callback != null)
          callback(policy, 0, '');
      }
      else{
          let details = data["error-details"];
          let code = details.code;
          let description = details.description;

          if(callback != null)
            callback(null, code, description);
      }
    })
    .catch(function(error) {
      if (callback != null) callback(null,-99, error.message);
    });
  }

  public getHeader() {
    return {
        "Content-Type": "application/json",
        Authorization: "NCC token=" + this.token,
    };
}

  public async getIdentity(callback: LoginCallbackType)
  {
    axios
    .get(this.baseURL + "/api/3.0/identities/?email=" + encodeURIComponent(email), {
      headers: this.getHeader()})
    .then(response => {
      
      let data = response.data;
      if(data.hasOwnProperty("identities"))
      {
        let serverIdentity = data.identities[0];
        identity = serverIdentity.guid;

        this.shareApi.setIdentity(identity);

        if (callback != null) callback(0, "");
      }
      else{
          let details = data["error-details"];
          let code = details.code;
          let description = details.description;

          if (callback != null) callback(code, description);
      }
    })
    .catch(function(error) {
      if (callback != null) callback(-99, error.message);
    });
  }

  public async share(contactsTo: string[], name: string, message: string, selectedOptionIndex: number, callback: ShareCallbackType)
  {
    this.shareApi.callback = function(message: string, progress: number, showProgress: boolean, activateButton: boolean){
        if(callback != null)
          callback(message, progress, showProgress, activateButton);
    };
    this.shareApi.getShareID(contactsTo, name, message, policy, selectedOptionIndex);
  }


  private onLogMessage = (logMessage: LogMessage) => {
   // this.logBuffer.enq(logMessage);
    //this.setState({ logMessages: this.logBuffer });
    console.log(((logMessage.type == LogMessageType.info) ? 'INFO: ' : 'ERROR: ') + logMessage.text);
  }
}
