import { Type } from "class-transformer";
import { IsEnum } from 'class-validator';

export const Version: string = "v1.0.8";

export enum FieldType {
  field = "field",
  header = "header"
}

/**
 * This enum describes the field where the policy keyword will be added.
 */
export enum KeywordDestination {
  header,
  subject,
  body,
  ignore
}

/**
 * This enum defines the type of a debug log message.
 */
export enum LogMessageType {
  info,
  error
}

/**
 * This class contains the information and text of a debug log message.
 */
export class LogMessage {
  type: LogMessageType;
  text: string;

  constructor(type: LogMessageType, text: string) {
    this.type = type;
    this.text = text;
  }
}

export interface PolicyOption {
  id: number;
  name: string;
  description?: string;
  shareOptions: ShareOptions;
  keywordTarget: { [id: string]: KeywordTarget };
}

export interface KeywordTarget {
  destination: KeywordDestination;
  value: string;
  headerName: string;
}

/**
 * This interface describes the data that are saved as part of each message's
 * metadata, to keep track of the selected policy in case of multiple compose
 * boxes being open (or a draft message being reloaded).
 */
export class MessagePrivateData {
  destination: KeywordDestination;
  headerName: string;
  keyword: string;
  policyId: number;
  headersSupported: boolean;

  public clear() {
    this.setKeyword(KeywordDestination.ignore, null, null);
    this.policyId = -1;
    this.headersSupported = false;
  }

  public setKeyword(destination: KeywordDestination, headerName: string, keyword: string) {
    this.destination = destination;
    this.headerName = headerName;
    this.keyword = keyword;
  }
}

export class Rule {
  id: number;

  @IsEnum(FieldType)
  field_type: FieldType;

  field_name: string;
  operand: string;
  value: string;

  public getDestination(): KeywordDestination {

    if (equalsCaseInsensitive("subject", this.field_name)) {
      return KeywordDestination.subject;
    }
    if (equalsCaseInsensitive("body", this.field_name)) {
      return KeywordDestination.body;
    }
    else if (this.field_type == FieldType.header) {
      return KeywordDestination.header;
    }

    return KeywordDestination.ignore;
  }
}

export class ShareOptions {
  id: string;
  //pin_security_options: null;
  is_default: false;
  terms_of_use_group_id: string;
  can_read: boolean;
  can_read_auo: boolean;
  can_create: boolean;
  can_create_auo: boolean;
  can_edit: boolean;
  can_edit_auo: boolean;
  can_delete: boolean;
  can_delete_auo: boolean;
  can_download: boolean;
  can_download_auo: boolean;
  watermark: boolean;
  watermark_auo: boolean;
  login_required: boolean;
  login_required_auo: boolean;
  require_terms_of_use: boolean;
  require_terms_of_use_auo: boolean;
  show_terms_once: boolean;
  show_terms_once_auo: boolean;
  one_time_link: boolean;
  one_time_link_auo: boolean;
  send_pin_on_email: boolean;
  send_pin_on_email_auo: boolean;
  pin_required: boolean;
  pin_required_auo: boolean;
  expiration_seconds: number;
  expiration_seconds_auo: boolean;
  max_expiration_seconds: null;
  max_recipients: number;
  apply_branding: boolean;
  apply_branding_auo: boolean;
  secure_message_body: boolean;
  secure_message_body_auo: boolean;
  active: boolean;
  deleted: boolean;
  organization_id: string;
  name: string;
  description: string;
}

export class Ruleset {
  'share-options-id': number;
  description?: string;
  id: string;
  name: string;

  @Type(() => Rule)
  rules: Array<Rule>;
}

export class CwpPolicy {

  @Type(() => Ruleset)
  rulesets: Ruleset[];

  @Type(() => ShareOptions)
  'share-options': ShareOptions[];
}

export function equalsCaseInsensitive(str1: string, str2: string) {
  return str1.localeCompare(str2, undefined, { sensitivity: 'accent' }) === 0;
}