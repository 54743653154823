import axios from "axios";
import * as Policy from "./Policies";

import { ShareItemApi } from "./ShareItemApi";

export class ShareOldItemApi extends ShareItemApi {

    protected printLog()
    {
        console.log("Class ShareOldItemApi");
    }

    public async getShareID(contactsTo: string[], name: string, message: string, policy: Policy.CwpPolicy, selectedOptionIndex: number) {
        if (message.length == 0 && contactsTo.length == 0) 
            this.sendShare();
        else {
          let uploadMethodIsCalled = false;
          if (message.length != 0) {
            uploadMethodIsCalled = true;
    
            let json = {
              reply_to_id: this.shareRequest.previousMessageID,
              content: message,
              client_created_at: new Date().toISOString(),
            };
    
            axios
              .post(
                this.base +
                  "/api/3.1/shares/" +
                  this.shareRequest.shareID +
                  "/messages",
                json,
                {
                  headers: this.getHeader(),
                }
              )
              .then((response1) => {
                this.sendShare();
              })
              .catch((error1) => {
                this.sendShare();
              });
          }
    
          if (contactsTo.length != 0) {
            let json = {
              recipients: contactsTo,
              identity_id: this.identity,
            };
    
            axios
              .patch(
                this.base + "/api/3.1/shares/" + this.shareRequest.shareID,
                json,
                {
                  headers: this.getHeader(),
                }
              )
              .then((response) => {
                if (!uploadMethodIsCalled) this.sendShare();
              })
              .catch((error) => {
                console.log(error);
                if (!uploadMethodIsCalled) this.sendShare();
              });
          }
        }
      }

      protected async sendShare() {
        if (this.callback != null){
          this.createSuccessURL().then(msg => {
            this.callback("Share is sent." + msg, 0, false, true);
        })
      }
    }
}