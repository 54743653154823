import "reflect-metadata";
import * as Policy from './Policies';
import { plainToClass } from "class-transformer";
import axios, { AxiosPromise } from 'axios';
import OutlookActions from './OutlookActions';

export class CachedPolicyOption {
  policyOptionList: { [id: number]: Policy.PolicyOption; };
  timestamp: Date;
  version: string;

  private isSavedVersionSameAsWebappVersion(): boolean {
    return this.version && (this.version == Policy.Version);
  }

  private isCachedPolicyEmpty(): boolean {
    return Object.keys(this.policyOptionList).length == 0;
  }

  private hasSavedPolicyExpired(cacheExpirationTimeout: number): boolean {
    let now = new Date();
    return ((this.timestamp.getTime() + cacheExpirationTimeout) < now.getTime());
  }

  public isValid(cacheExpirationTimeout: number): boolean {
    return this.isSavedVersionSameAsWebappVersion()
      && !this.isCachedPolicyEmpty()
      && !this.hasSavedPolicyExpired(cacheExpirationTimeout);
  }
}

export class SecureApi {
  protected logger: (logMessage: Policy.LogMessage) => void;

  protected outlookActions: OutlookActions;
  protected policyOptions: { [id: number]: Policy.PolicyOption; } = {};
  protected serverUrl = 'https://www.ncryptedcloud.com/api/2.0/policy/get/';
  protected cacheExpirationTimeout = 10 * 60 * 1000;  // 10 mins in milliseconds

  constructor(logger: (logMessage: Policy.LogMessage) => void) {

    this.logger = logger;
    //this.outlookActions = new OutlookActions(logger);
  }

  public async init(url: string): Promise<void> {
    if (url && url != '') {
      this.serverUrl = url + '/api/2.0/policy/get/';
    }

    //return this.outlookActions.init();
  }

  //TODO: Add the checks for the organization id
  protected checkOrganization(policy) {
    if (policy == undefined) {
      return false;
    }
    return true;
  }

  public setMessageChangeCallback(cb: (item?: any) => void) {
    this.outlookActions.setMessageChangeCallback(cb);
  }

  /**
   * Performs message update action to add the relevant policy keywords to message.
   *
   * @param destination header, body, subject
   * @param headerName if destination is header, it contains the name of the header
   * @param value the keyword to be added
   */
  public setPolicyKeyword(targets: { [id: string]: Policy.KeywordTarget }, policyId: number) {
    this.outlookActions.setPolicyKeyword(targets, policyId);
  }

  /**
   * Returns the policy that was assigned to the message in the past,
   * or -1 if no policy was ever assigned (this applies to brand new messages).
   */
  public getPreviousPolicy(): Promise<number> {
    return this.outlookActions.getPreviouslySelectedPolicy();
  }

  /**
   * It adds a new policy option in the list of policies to be presented to the user.
   *
   * @param policy the policy object to be added to the list
   * @param rule the rule that derives the policy object
   */
  protected addPolicyToList(policy: Policy.PolicyOption, rule: Policy.Rule) {
    let destination = rule.getDestination();

    if (destination == Policy.KeywordDestination.ignore) {
      return;
    }

    if (!this.policyOptions[policy.id]) {
      this.policyOptions[policy.id] = {} as Policy.PolicyOption;
      this.policyOptions[policy.id].keywordTarget = {} as { [id: string]: Policy.KeywordTarget };
    }

    this.policyOptions[policy.id].id = policy.id;

    this.policyOptions[policy.id].keywordTarget[destination] = {
      destination,
      headerName: rule.field_name,
      value: rule.value
    };
  }

  /**
   * Adds the sharing option json to the sharing policy object.
   * It also updates the name and description of the sharing policies from the sharing options,
   * that are more verbose.
   *
   * @param policy the CWP policy that contains the sharing options
   */
  protected updatePolicySharingOptions(policy: Policy.CwpPolicy) {

    for (let policyOptionIdx in this.policyOptions) {
      let policyOption = this.policyOptions[Number(policyOptionIdx)];

      let shareOption = policy['share-options'].filter(item => item.id === policyOptionIdx);
      policyOption.shareOptions = shareOption ? shareOption[0] : null;

      if (policyOption.shareOptions) {
        policyOption.name = policyOption.shareOptions.name;
        policyOption.description = policyOption.shareOptions.description;
      }
      else {
        policyOption.description = null;
      }
    }
  }

  /**
   * This function adds the 'no secure policy' option to the policy list.
   */
  protected addNonSecurePolicyToList() {
    let policy = {} as Policy.PolicyOption;

    policy.description = 'This policy will not apply a security profile to the message.';
    policy.name = 'No secure policy';
    policy.id = -1;
    policy.shareOptions = null;
    policy.keywordTarget = {};

    policy.keywordTarget[Policy.KeywordDestination.ignore] = {
      destination: Policy.KeywordDestination.ignore,
      headerName: null,
      value: null
    };

    this.policyOptions[policy.id] = policy;
  }

  /**
   * Processes CWP policy response to extract the policy names, policy fields to be added to
   * the email message and the sharing options of each policy.
   *
   * When it encounters rules with different fields, it prefers the rules that trigger on a MIME header
   * (rather than subject or body keywords).
   *
   * @param data the json policy object
   */
  public parsePolicyResponse(data, addNoPolicy: boolean) {
    this.policyOptions = {};
    let policies: Policy.CwpPolicy[];

    try {
      let message = data["message"];
      if (message == undefined) {
        return null;
      }

      policies = plainToClass(Policy.CwpPolicy, message["policies"] as Policy.CwpPolicy[]);
      if (policies == undefined) {
        return null;
      }

      for (let policy of policies) {
        if (!this.checkOrganization(policy)) {
          continue;
        }

        if(policy.rulesets != null){
          for (let ruleset of policy.rulesets) {
            let policyOption = {} as Policy.PolicyOption;

            policyOption.id = ruleset['share-options-id'];
            policyOption.name = ruleset.name;

            for (let rule of ruleset.rules as Policy.Rule[]) {
              this.addPolicyToList(policyOption, rule);
            }
        }

        this.updatePolicySharingOptions(policy);
        }
      }

      if(addNoPolicy)
        this.addNonSecurePolicyToList();
    }
    catch (err) {
      this.logger(new Policy.LogMessage(Policy.LogMessageType.error, 'Failed to parse policy. Error: ' + err));
    }

    return { options: this.policyOptions, cwpPolicy: policies };
  }

  /**
   * It performs a REST call to CWP to retrieve the json representation of the
   * sharing policies.
   *
   * @param accessToken the outlook token of the current user that is used
   * as a Bearer Authorization token to access CWP.
   */
  public getPolicyList(accessToken: string): AxiosPromise<any> {
    return axios.post(this.serverUrl, '{\"message\": {}, \"auth-info\":{}}', {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + accessToken
      },
      timeout: 5000
    });
  }

  protected removeHeaderOnlyRules(policyOptions: { [id: number]: Policy.PolicyOption }) {
    for (let policyId in policyOptions) {
      let policy = policyOptions[policyId];

      if (policy.keywordTarget[Policy.KeywordDestination.header]) {
        delete policy.keywordTarget[Policy.KeywordDestination.header];
      }
    }
  }

  /**
   * This function retrieves all the sharing policies from the CWP and
   * updates the UI selection elements.
   */
  public async getPoliciesFromCwp(forceReload: boolean = false): Promise<any> {
    if (!forceReload) {
      let cachedPolicyList: CachedPolicyOption = plainToClass(CachedPolicyOption, this.outlookActions.loadRoamingData('policyList') as CachedPolicyOption);
      let policyId: number;

      if (cachedPolicyList && cachedPolicyList.isValid(this.cacheExpirationTimeout)) {
        return this.outlookActions.getPreviouslySelectedPolicy()
          .then((polId: number) => {
            policyId = polId;
            return this.outlookActions.isHeadersSupported();
          })
          .then((headersSupported: boolean) => {
            if (!headersSupported) {
              this.removeHeaderOnlyRules(cachedPolicyList.policyOptionList);
            }

            return {
              policyList: cachedPolicyList.policyOptionList,
              policyId: policyId,
              cwpPolicy: null
            };
          });
      }
    }

    let policies;
    let cwpPolicy: Policy.CwpPolicy;
    let policyId: number;

    return this.outlookActions.getPreviouslySelectedPolicy()
      .then((id) => {
        policyId = id;
        return this.outlookActions.getO365UserToken();
      })
      .then((token) => {
        this.logger(new Policy.LogMessage(Policy.LogMessageType.info, 'Token: ' + token.value));
        return this.getPolicyList(token.value);
      })
      .then((result: any): any => {

        if (result.data['error-code'] != 0) {
          throw new Error('Error accessing CWP [' + this.serverUrl + ']. Error: [' + result.data['error-details'].code + '] '
            + result.data['error-details'].description);
        }

        let reply = this.parsePolicyResponse(result.data, true);
        policies = reply.options;
        cwpPolicy = reply.cwpPolicy[0];

        let cachedPolicyList = {} as CachedPolicyOption;
        cachedPolicyList.timestamp = new Date();
        cachedPolicyList.policyOptionList = policies;
        cachedPolicyList.version = Policy.Version;

        return this.outlookActions.saveRoamingData('policyList', cachedPolicyList);
      })
      .then(() => {
        return this.outlookActions.isHeadersSupported();
      })
      .then((headersSupported: boolean) => {
        if (!headersSupported) {
          this.removeHeaderOnlyRules(policies);
        }

        return {
          policyList: policies,
          policyId: policyId,
          cwpPolicy: cwpPolicy
        };
      })
      .catch((err: Error) => {
        this.logger(new Policy.LogMessage(Policy.LogMessageType.error, err.message));
        return {
          policyList: null,
          policyId: -1,
          cwpPolicy: null
        };
      });
  }
}
