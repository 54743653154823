import axios from "axios";
import * as Policy from "./Policies";

import { ShareFileApi } from "./ShareFileApi";

export class ShareOldFileApi extends ShareFileApi {

  protected printLog()
  {
    console.log("Class ShareOldFileApi");
  }

  public async getShareID(contactsTo: string[], name: string, message: string, policy: Policy.CwpPolicy, selectedOptionIndex: number) {
    if (message.length == 0 && contactsTo.length == 0) 
        this.getUploadID();
    else {
      let uploadMethodIsCalled = false;
      if (message.length != 0) {
        uploadMethodIsCalled = true;

        let json = {
          reply_to_id: this.shareRequest.previousMessageID,
          content: message,
          client_created_at: new Date().toISOString(),
        };

        axios
          .post(
            this.base +
              "/api/3.1/shares/" +
              this.shareRequest.shareID +
              "/messages",
            json,
            {
              headers: this.getHeader(),
            }
          )
          .then((response1) => {
            this.getUploadID();
          })
          .catch((error1) => {
            this.getUploadID();
          });
      }

      if (contactsTo.length != 0) {
        let json = {
          recipients: contactsTo,
          identity_id: this.identity,
        };

        axios
          .patch(
            this.base + "/api/3.1/shares/" + this.shareRequest.shareID,
            json,
            {
              headers: this.getHeader(),
            }
          )
          .then((response) => {
            if (!uploadMethodIsCalled) this.getUploadID();
          })
          .catch((error) => {
            console.log(error);
            if (!uploadMethodIsCalled) this.getUploadID();
          });
      }
    }
  }

  protected async sendShare() {
    if (this.callback != null){
      this.createSuccessURL().then(msg => {
        this.callback("Share is sent." + msg, 0, false, true);
    })
    }
  }
}
