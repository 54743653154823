import * as Policy from "./Policies";

export class ShareRequest{
    shareID : string;
    mountID : string;
    parentID: string;
    shareMessageID : string;
    name : string;
    message : string;
    selectedOption: Policy.ShareOptions;
    filename : string;
    previousMessageID: number;
    contactsTo: string[];

    existingShare: boolean;
    
    constructor()
    {
        this.shareID = "";
        this.mountID = "";
        this.parentID = "";
        this.filename = "";
        this.message = "";
        this.name = "";
        this.previousMessageID = -1;
        this.shareMessageID = "";
        this.contactsTo = [];
        this.selectedOption = null;
        this.existingShare = false;
    }
}