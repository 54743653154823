import axios from "axios";
import * as Policy from "./Policies";
import {ShareGenericApi, UploadCallbackType} from "./ShareGenericApi";

import { Md5 } from "ts-md5/dist/md5";
import { ParallelHasher } from "ts-md5/dist/parallel_hasher";

export class ShareFileApi extends ShareGenericApi{

    protected uploadItemID: string 
    protected parentItemID: string;

    constructor(_token: string, _base: string) {
        super(_token, _base);

        this.uploadItemID = "";
        this.parentItemID = "";
    }

    protected printLog()
    {
        console.log("Class ShareFileApi");
    }



    public async getShareID(contactsTo: string[], name: string, message: string, policy: Policy.CwpPolicy,
        selectedOptionIndex: number) 
    {
        var ext = name.substr(name.lastIndexOf("."));

        var options = policy["share-options"];
        this.shareRequest.selectedOption = options[selectedOptionIndex];
        this.shareRequest.name = name.replace(ext, "");
        this.shareRequest.message = message;
        this.shareRequest.filename = name;
        this.shareRequest.contactsTo = contactsTo;

        this.callShareEndPoint("initialize", (status, data, error) => {
            if(status){
                if (data.hasOwnProperty("share_id")) {
                    this.shareRequest.shareID = data.share_id;
                    this.shareRequest.mountID = data.mount_id;
                    this.shareRequest.parentID = data.parent_id;
                    this.shareRequest.shareMessageID = data.share_message_id;

                    this.getUploadID();
                }
            }
            else
            {
                if (this.callback != null)
                    this.callback(error, 0, false, true);
            }
        });
    }

    protected async getUploadID() 
    {
        this.printLog();
        console.log("From uploadID");

        let that = this;
        Office.context.document.getFileAsync(
            Office.FileType.Compressed,
            function (result) {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    // If the getFileAsync call succeeded, then
                    // result.value will return a valid File Object.
                    var myFile = result.value;
                    var sliceCount = myFile.sliceCount;
                    var gotAllSlices = true;
                        
                        console.log("File size:" + myFile.size + " #Slices: " + sliceCount);

                        let body = {
                            mount_id: that.shareRequest.mountID,
                            parent_id: that.shareRequest.parentID,
                            file_name: that.shareRequest.filename,
                            size: myFile.size,
                            share_message_id: that.shareRequest.shareMessageID,
                            overwrite: true,
                            encrypt:true,
                        };
                
                        axios
                            .post(that.base + "/api/3.0/uploads/", body, {
                                headers: that.getHeader(),
                            })
                            .then(function (response) {
                                let data = response.data;
                                if (data.hasOwnProperty("upload_id")) {
                                    that.uploadItemID = data.upload_id;
                                    that.parentItemID = data.parent_id;
                
                                    if(that.callback != null)
                                        that.callback("Uploading file", 0, true, false);

                                    that.getSliceAsync(
                                        myFile,
                                        0,
                                        sliceCount,
                                        gotAllSlices,
                                        0
                                    );
                                }
                            })
                            .catch(function (error) {
                                
                                if(that.callback != null)
                                    that.callback(error.message, 0, false, true);
                            });
                } else {
                    
                    if(that.callback != null)
                        that.callback(result.error.message, 0, false, true);
                    //app.showNotification("Error:", result.error.message);
                }
            }
        );
    }

    private getSliceAsync(
        file,
        nextSlice,
        sliceCount,
        gotAllSlices,
        slicesReceived
    ) {
        let that = this;
        file.getSliceAsync(nextSlice, function (sliceResult) {
            if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
                if (!gotAllSlices) {
                    // Failed to get all slices, no need to continue.
                    return;
                }

                var docdataSlices = [];
                // Got one slice, store it in a temporary array.
                // (Or you can do something else, such as
                // send it to a third-party server.)
                docdataSlices = sliceResult.value.data;

                that.onUploadChunk(docdataSlices, nextSlice, slicesReceived == (sliceCount - 1), sliceCount, function(status){
                    if(status){
                        if (slicesReceived == (sliceCount - 1)) {
                            // All slices have been received.
                            file.closeAsync();
                            that.sendShare();
                        } else {
                            that.getSliceAsync(
                                file,
                                ++nextSlice,
                                sliceCount,
                                gotAllSlices,
                                ++slicesReceived
                            );
                        }
                    }
                });
            } else {
                gotAllSlices = false;
                file.closeAsync();
                //app.showNotification("getSliceAsync Error:", sliceResult.error.message);
            }
        });
    }

    
    private onUploadChunk(docdataSlices, chunkNumber: number, lastChunk: boolean, sliceCount: number, callback: UploadCallbackType) {
        /*var docdata = [];
        for (var i = 0; i < docdataSlices.length; i++) {
            docdata = docdata.concat(docdataSlices[i]);
        }*/

        var fileContent = new String();
        for (var j = 0; j < docdataSlices.length; j++) {
            fileContent += String.fromCharCode(docdataSlices[j]);
        }

        // Now all the file content is stored in 'fileContent' variable,
        // you can do something with it, such as print, fax...

        // upload the file

        let that = this;

        let bytes = new Uint8Array(fileContent.length);

        for (let i = 0; i < bytes.length; i++) {
            bytes[i] = fileContent.charCodeAt(i);
        }

        var blob = new Blob([bytes], {type: 'application/octet-stream'});


        let hasher = new ParallelHasher(
            "md5_worker.js"
        );
        hasher.hash(blob).then(function (result) {
            console.log("md5 of fileBlob is", result);
            var formData = new FormData();
            formData.append("parent_id", that.parentItemID);
            formData.append("mount_id", that.shareRequest.mountID);
            formData.append("file_name", that.shareRequest.filename);
            formData.append("checksum", result);

            formData.append("chunk", blob);

            /*var stringBoundary = "sm_boundary";
            var contentType = "multipart/form-data;boundary=" + stringBoundary;


            formData += "\r\n--" + stringBoundary + "\r\n";
            formData += "Content-Disposition:form-data;name=\"parent_id\"\r\n\r\n" + parentItemID + "\r\n--" + stringBoundary + "\r\n";
            formData += "Content-Disposition:form-data;name=\"mount_id\"\r\n\r\n" +  shareRequest.mountID + "\r\n--"  + stringBoundary + "\r\n";
            formData += "Content-Disposition:form-data;name=\"file_name\"\r\n\r\n" + shareRequest.name + "\r\n--" + stringBoundary + "\r\n";
            formData += "Content-Disposition:form-data;name=\"checksum\"\r\n\r\n" + result + "\r\n--" + stringBoundary + "\r\n";
            formData += "Content-Disposition:form-data;name=\"chunk\";filename=\"demo\"\r\n";
            formData += "Content-Transfer-Encoding: binary\r\n\r\n";
            formData += fileContent;


            formData += "\r\n--" + stringBoundary + "--\r\n";*/

            let url = "/api/3.0/uploads/" + that.uploadItemID + "/" + chunkNumber;

            if(lastChunk)
                url += "-/";
            else
                url += "/";

            axios
                .post(
                    that.base + url,
                    formData,
                    {
                        onUploadProgress: function(progressEvent) {
                            var percentCompleted = progressEvent.loaded / progressEvent.total;
                            var previousProgress = chunkNumber / sliceCount;
                            var currentProgress = 1 / sliceCount;

                            var totalProgress = Math.round(100 * (previousProgress + currentProgress * percentCompleted));

                            if(that.callback != null)
                                that.callback("", totalProgress, true, false);
                          },
                          headers: {
                            //"Content-Type":contentType,
                            Authorization: "NCC token=" + that.token,
                        }
                    }
                )
                .then(function (response) {
                    if(callback != null)
                        callback(true);
                })
                .catch(function (error) {
                    if(callback != null)
                        callback(false);
                });
        });
    }

    protected async sendShare() {
        this.callShareEndPoint("send", (status, data, error)=>{
            if(status) {
                Office.context.document.settings.set("shareID", this.shareRequest.shareID);
                Office.context.document.settings.set("mountID", this.shareRequest.mountID);
                Office.context.document.settings.set("parentID", this.shareRequest.parentID);
                Office.context.document.settings.set("fileName", this.shareRequest.filename);
                Office.context.document.settings.set("shareMessageID", this.shareRequest.shareMessageID);
                Office.context.document.settings.set("optionName", this.shareRequest.selectedOption.name);

                Office.context.document.settings.saveAsync(function(result) {
                if (result.status === Office.AsyncResultStatus.Succeeded)
                    console.log("share info is stored");
                else console.log("share info is NOT stored");
                });

                if(this.callback != null){
                    this.createSuccessURL().then(msg => {
                        this.callback(msg, 0, false, true);
                    })
                }

                    // this is code is not used, but keep for future reference

              /*if (shareRequest.selectedOption.watermark) {
                if (that.callback != null)
                  that.callback("Syncing file...", 0, false, false);

                that.getItemMountID();
              } else {
                if (that.callback != null)
                  that.callback(
                    "Share is sent. <a target='_blank' href='" +
                      base +
                      "/shares/" +
                      shareRequest.shareID +
                      "'>Click here to join collaborators</a>",
                    0,
                    false,
                    true
                  );
              }*/
                
            } else {
                    if (this.callback != null)
                        this.callback( "Share is not sent " + error, 0, false, true);
                }
            }); 
    }
}
