const allowedTags = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'table',
    'thead',
    'tbody',
    'th',
    'tr',
    'td',
    'ul',
    'ol',
    'li',
    'br',
    'blockquote',
    'div',
    'p',
    'span',
    'b',
    'strong',
    'i',
    'em',
    'a',
    'del',
    'u',
    'pre',
    'font',
    'img'
];

const regExps = {
    wrap: {
        opened: new RegExp(`<(${allowedTags.join('|')})(?=\\W)([^>]*)>`, 'gm'),
        closed: new RegExp(`<\\/(${allowedTags.join('|')})>`, 'gm'),
    },
    unwrap: {
        opened: new RegExp(`\\[(${allowedTags.join('|')})(?=\\W)([^\\]]*)\\]`, 'gm'),
        closed: new RegExp(`\\[\\/(${allowedTags.join('|')})\\]`, 'gm'),
    },
};

const wrap = (content: string = ''): string => {
    return content
        .replace(regExps.wrap.opened, '[$1 $2]')
        .replace(regExps.wrap.closed, '[/$1]');
};

const unwrap = (content: string = ''): string => {
    return content
        .replace(regExps.unwrap.opened, '<$1$2>')
        .replace(regExps.unwrap.closed, '</$1>');
};

/**
 * Exports
 */
export default {
    wrap,
    unwrap,
};
