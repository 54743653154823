import {ShareRequest} from "./ShareRequest";
import axios from "axios";
import * as Policy from "./Policies";

const config = require('../taskpane/config.json');

interface ShareCallbackType { (message: string, progress: number, showProgress: boolean, activateButton: boolean): void }

interface ShareEndPointCallbackType { (status: boolean, message: any, error: string): void }

export interface UploadCallbackType { (status: boolean): void }

export class ShareGenericApi {
    public callback : ShareCallbackType;
    public shareRequest : ShareRequest;

    protected base = "";
    protected identity = "";
    protected token = "";

    private successURL = "";

    constructor(_token: string, _base: string) {
        this.token = _token;
        this.base = _base;

        this.shareRequest = new ShareRequest();

        if(config.type !== "production")
            this.printLog();
    }

    protected printLog()
    {
        
    }

    public clone(from: ShareGenericApi)
    {
        this.token = from.token;
        this.base = from.base;
        this.identity = from.identity;
        
        this.shareRequest = from.shareRequest;
    }

    public setIdentity(_identity: string){
        this.identity = _identity;
    }

    protected getHeader() {
        return {
            "Content-Type": "application/json",
            Authorization: "NCC token=" + this.token,
        };
    }

    protected getOptions() {
        var optionsObj = {
            watermark: this.shareRequest.selectedOption.watermark,
            can_read: this.shareRequest.selectedOption.can_read,
            can_download: this.shareRequest.selectedOption.can_download,
            can_delete: this.shareRequest.selectedOption.can_delete,
            can_edit: this.shareRequest.selectedOption.can_edit,
            can_create: this.shareRequest.selectedOption.can_create,
            login_required: this.shareRequest.selectedOption.login_required,
            accessCode: this.shareRequest.selectedOption.pin_required,
            send_pin_on_email: this.shareRequest.selectedOption.send_pin_on_email,
            expiration: this.shareRequest.selectedOption.expiration_seconds,
            message: this.shareRequest.message,
            secure_conversation_enabled: true
        };
        return optionsObj;
    }

    protected getShareBody(action: string) {
        let body = {
            recipients: this.shareRequest.contactsTo,
            category: "TS",
            share_name: this.shareRequest.name,
            action: action,
            sharing_policy_id: this.shareRequest.selectedOption.id,
            options: this.getOptions(),
            identity_id: this.identity,
          };

        if(this.shareRequest.shareID.length != 0)
            body["share_id"] = this.shareRequest.shareID;

        if(this.shareRequest.shareMessageID.length != 0)
            body["share_message_id"] = this.shareRequest.shareMessageID;

          return body;
    }

    public async getShareID(contactsTo: string[], name: string, message: string, policy: Policy.CwpPolicy,
        selectedOptionIndex: number) 
    {
    }

    protected callShareEndPoint(action: string, shareCallback: ShareEndPointCallbackType)
    {
        axios
            .post(this.base + "/api/3.0/shares/", this.getShareBody(action), {
              headers: this.getHeader(),
            })
            .then(response => {
              let data = response.data;
              if(shareCallback != null)
                shareCallback(true, data, "");
            })
            .catch(error => {
                if(shareCallback != null)
                    shareCallback(false, "", error.message);
            });
    }

    public createSuccessURL()
    {
        return new Promise<string>((resolve) => {
            if(this.successURL.length == 0){
                this.getItemMountID()
                    .then(url => {
                        let name = this.shareRequest.filename;
                        this.successURL = "<a target='_blank' href='" + url + "?filename=" + name + "&action=edit" + "'>Join collaborators</a>";     
                        return resolve(this.successURL);
                });
            } else {
                return resolve(this.successURL);
            }

        });
    }

    private getItemMountID() {
        return new Promise<string>((resolve) => {
            axios
                .get(this.base + "/api/3.0/shares/" + this.shareRequest.shareID + "?include_owner=true&include_recipients=true", {
                headers: this.getHeader(),
                })
                .then(response => {
        
                    let data = response.data;
                    let share = data.share;
                    let recipients = share.recipients;
                    let url = "";
                    for(let i=0; i<recipients.length; i++)
                    {
                        let recipient = recipients[i];
                        if(recipient.is_owner)
                        {
                            url = recipient.url;
                            break;
                        }
                    }

                    resolve(url);
            })
            .catch(error => {
                if(this.callback != null)
                    this.callback("Share is not sent " + error.message, 0, false, true);
                }
            );
        });
    }

    

    private async getItem(mountID: string, count: number){
        
        let that = this;
        axios
            .get(this.base + "/api/3.0/mounts/" + mountID + "/items", {
                headers: this.getHeader(),
            })
            .then(function (response) {
        
                let data = response.data;
                if(data.items.length > 0)
                {
                    let item = data.items[0];
                    let urlString = item.view_url;

                    let url = "";
                    if(!that.shareRequest.selectedOption.watermark)
                    {
                        let urlObj = new URL(urlString);
                        let path = urlObj.searchParams.get("path");

                        path = path.replace("?app=default", "");

                        let host_edit_url = path + "%26app%3Doffice-online%26operation%3Dedit";
                        let host_view_url = path + "%26app%3Doffice-online%26operation%3Dview";
                        let parent_folder_url = that.base + "/shares/" + that.shareRequest.shareID;
                        let name = item.name;
                        let rev = item.revision;
                        let revSplit = rev.split(",");
                        let revision = "";
                        if(revSplit.length == 1)
                            revision = revSplit[0];
                        else
                            revision = revSplit[1];

                        let itemID = item.id;
                        

                        url = this.base + "/api/3.0/mounts/" + mountID + "/items/" + itemID + "/edit?is_folder=False&revision=" + revision;

                        url += "&host_edit_url=" + host_edit_url;
                        url += "&host_view_url=" + host_view_url;
                        url += "&parent_folder_url=" + parent_folder_url;

                        url += "&parent_folder_name=Files";
                        url += "&office_operation=view";
                   
                    }
                    else
                    {
                        url = urlString.replace("?app=default", "");
                        url += "&action=open&filename=" + that.shareRequest.filename;
                    }

                    if(that.callback != null)
                        that.callback("Share is sent. <a target='_blank' href='" + url + "'>Join collaborators</a>", 0, false, true);
                }
                else
                {
                    // attempt 10 times to get the itemID. If that is not possible, then take the user to the TS page
                    if(count < 10)
                    {
                        setTimeout(() => {
                            that.getItem(mountID, ++count);
                        }, 7000);
                    }
                    else
                    {
                        if(that.callback != null)
                            that.callback("Share is sent. <a target='_blank' href='" + this.base + "/shares/" + that.shareRequest.shareID + "'>Join collaborators</a>", 0, false, true);

                    }
                }
            })
            .catch(function (error) {
                if(that.callback != null)
                    that.callback("Share is not sent " + error.message, 0, false, true);
            });
    }

}